<template>
  <div class="d-flex flex-column flex-root">
    <div
      class="login login-1 d-flex flex-column flex-lg-row flex-column-fluid bg-white"
      :class="{
        'login-signin-on': this.state === 'signin',
        'login-signup-on': this.state === 'signup',
        'login-forgot-on': this.state === 'forgot'
      }"
      id="kt_login"
    >
      <!--begin::Aside-->
      <div
        class="login-aside d-flex flex-column flex-row-auto"
        style="background-color: #b1d7ed"
      >
        <div
          class="aside-img d-flex flex-row-fluid bgi-no-repeat bgi-position-y-bottom bgi-position-x-center"
          style="
            background-image: url(media/bg/merdeka.png);
            background-size: contain;
            width: 100%;
          "
        ></div>
      </div>
      <!--begin::Aside-->
      <!--begin::Content-->
      <div
        class="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto"
      >
        <div class="d-flex flex-column-fluid flex-center">
          <!--begin::Signin-->
          <div class="login-form login-signin">
            <form
              class="form"
              id="kt_login_signin_form"
              novalidate="novalidate"
            >
              <div class="pb-13 pt-lg-0 pt-5">
                <a href="#" class="row d-flex justify-content-center mb-7">
                  <img
                    src="media/svg/custom/logo-kemendagri-letter.svg"
                    alt="alternative"
                    style="width: 100%"
                  />
                </a>
              </div>
              <div class="row d-flex justify-content-center mb-9">
                <h1 class="text-dark font-weight-bold">Masuk</h1>
                <b-alert
                  show
                  variant="light"
                  style="color: #464e5f"
                  class="mt-5"
                  ><i class="fa fa-info-circle mr-2" style="color: #0a65ff"></i
                  >Untuk permohonan registrasi, dapat menghubungi email berikut:
                  <a
                    href="mailto:bagpuupolpum@gmail.com"
                    class="text-dark font-weight-bold text-hover-primary"
                    >bagpuupolpum@gmail.com</a
                  ></b-alert
                >
                <b-alert show variant="light" style="color: #464e5f">
                  <i class="fa fa-info-circle mr-2" style="color: #0a65ff"></i>
                  Demi meminimalisir kendala tampilan dan untuk penggunaan
                  aplikasi lebih optimal, silahkan menggunakan browser
                  <a
                    href="https://www.google.com/chrome"
                    class="text-dark font-weight-bold text-hover-primary"
                    target="_blank"
                    >Google Chrome</a
                  ></b-alert
                >
              </div>
              <div class="form-group">
                <label class="font-size-h6 font-weight-bolder text-dark"
                  >Username</label
                >
                <div
                  id="example-input-group-1"
                  label=""
                  label-for="example-input-1"
                >
                  <input
                    class="form-control form-control-solid h-auto py-5 px-6 rounded-lg"
                    type="email"
                    name="email"
                    ref="email"
                    placeholder="Username"
                    v-model="form.email"
                    autocomplete="off"
                    autocapitalize="none"
                    @input="form.email = $event.target.value.toLowerCase()"
                  />
                </div>
              </div>
              <div class="form-group">
                <div class="d-flex justify-content-between mt-n5">
                  <label class="font-size-h6 font-weight-bolder text-dark pt-5"
                    >Kata Sandi</label
                  >
                  <a
                    class="font-size-h6 font-weight-bolder text-hover-primary pt-5 cursor-pointer"
                    style=""
                    id="kt_login_forgot"
                    @click="showForm('forgot')"
                    >Lupa kata sandi ?</a
                  >
                </div>
                <div
                  id="example-input-group-2"
                  label=""
                  label-for="example-input-2"
                  class="input-group"
                >
                  <input
                    class="form-control form-control-solid h-auto py-5 px-6"
                    type="password"
                    name="password"
                    ref="password"
                    placeholder="Kata Sandi"
                    v-model="form.password"
                    autocomplete="off"
                  />
                  <span
                    class="input-group-append bg-blue-gray sticky-right-rounded-lg"
                  >
                    <button
                      v-on:click.prevent="showPassword"
                      class="btn"
                      type="button"
                    >
                      <i class="fa fa-eye"></i>
                    </button>
                  </span>
                </div>
              </div>
              <div class="pb-lg-0 pb-5">
                <button
                  ref="kt_login_signin_submit"
                  class="btn btn-primary font-weight-bolder btn-block font-size-h6 px-15 py-3 my-3 mr-3"
                  style="
                    background-color: #18328d;
                    color: #fff;
                    border-color: #18328d;
                  "
                >
                  Masuk
                </button>

                <!--                <button
                  type="button"
                  id="kt_login_signup"
                  class="btn btn-outline-warning btn-block text-dark font-weight-bolder px-8 py-4 my-3 font-size-lg mt-5"
                  @click="showForm('signup')"
                >
                  Aktivasi Akun
                </button>-->
              </div>
            </form>
            <div class="separator separator-dashed my-6"></div>
            <h5 class="d-flex justify-content-center">Atau dengan</h5>
            <div class="row">
              <div class="col-sm">
                <button
                  ref="kt_login_signin_sso"
                  class="btn btn-primary font-weight-bolder btn-block font-size-h6 px-15 py-3 my-3 mr-3"
                  style="
                    background-color: #464e5f;
                    color: #fff;
                    border-color: #464e5f;
                  "
                  @click="redirKominfoSSO()"
                  >
                  SSO Nasional
                </button>
              </div>
              <div class="col-sm">
                <button
                  ref="kt_login_signin_sso"
                  class="btn btn-primary font-weight-bolder btn-block font-size-h6 px-15 py-3 my-3 mr-3"
                  style="
                    background-color: #464e5f;
                    color: #fff;
                    border-color: #464e5f;
                  "
                  @click="redirPolpumSSO()"
                >
                  SSO Polpum
                </button>
              </div>
            </div>

            <div class="row mt-5">
              <div class="col-sm text-right">
                <a href="javascript:;" @click="showKebijakan"
                  >Kebijakan Privasi</a
                >
              </div>

              <div class="col-sm">
                <a href="javascript:;" @click="showSyarat"
                  >Syarat & Ketentuan</a
                >
              </div>
            </div>
          </div>
          <!--end::Signin-->
          <!--begin::Signup-->
          <div class="login-form login-signup">
            <form
              class="form"
              novalidate="novalidate"
              id="kt_login_signup_form"
            >
              <div class="pb-13 pt-lg-0 pt-5">
                <h3
                  class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"
                >
                  Aktivasi Akun
                </h3>
                <p class="text-muted font-weight-bold font-size-h4">
                  Masukkan data diri Anda untuk membuat akun.
                </p>
              </div>
              <div class="form-group">
                <input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                  type="text"
                  placeholder="Nama Lengkap"
                  name="fullname"
                  ref="fullname"
                  v-model="register.full_name"
                  autocomplete="off"
                />
              </div>
              <div class="form-group">
                <input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                  type="email"
                  placeholder="Email"
                  name="email"
                  v-model="register.email"
                  ref="email"
                  autocomplete="off"
                />
              </div>
              <div class="form-group">
                <input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                  type="password"
                  placeholder="Kata Sandi"
                  name="password"
                  v-model="register.password"
                  ref="rpassword"
                  autocomplete="off"
                />
              </div>
              <div class="form-group">
                <input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                  type="password"
                  placeholder="Konfirmasi Kata Sandi"
                  name="cpassword"
                  v-model="register.cpassword"
                  ref="cpassword"
                  autocomplete="off"
                />
              </div>
              <div class="form-group">
                <label class="checkbox mb-0">
                  <input type="checkbox" name="agree" />
                  <span class="mr-2"></span>
                  Saya menyetujui
                  <a href="#" class="ml-2">syarat dan ketentuan yang berlaku.</a
                  >.
                </label>
              </div>
              <div class="form-group d-flex flex-wrap pb-lg-0 pb-3">
                <button
                  ref="kt_login_signup_submit"
                  class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4"
                >
                  Kirim
                </button>
                <button
                  type="button"
                  id="kt_login_signup_cancel"
                  class="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3"
                  @click="showForm('signin')"
                >
                  Batal
                </button>
              </div>
            </form>
          </div>
          <!--end::Signup-->
          <!--begin::Forgot-->
          <div class="login-form login-forgot">
            <!--begin::Form-->
            <form
              class="form"
              novalidate="novalidate"
              id="kt_login_forgot_form"
              ref="kt_login_forgot_form"
            >
              <div class="pb-13 pt-lg-0 pt-5">
                <a href="#" class="row d-flex justify-content-center mb-7">
                  <img
                    src="media/svg/custom/logo-kemendagri-letter.svg"
                    style="width: 100%; margin-bottom: 20px"
                    alt="alternative"
                  />
                </a>
                <div class="row d-flex justify-content-center mb-7">
                  <h1 class="text-dark font-weight-bold">Lupa Kata Sandi</h1>
                </div>
              </div>
              <div class="form-group">
                <label class="font-size-h6 font-weight-bolder text-dark pt-5"
                  >Email</label
                >
                <div
                  id="input-group-lupa-password"
                  label=""
                  label-for="lupa-password"
                >
                  <input
                    id="email"
                    class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                    type="email"
                    v-model="forgot.email"
                    placeholder="Email"
                    name="email"
                    ref="email"
                    autocomplete="off"
                    autocapitalize="none"
                    @input="forgot.email = $event.target.value.toLowerCase()"
                  />
                </div>
              </div>
              <div class="form-group d-flex flex-wrap pb-lg-0">
                <button
                  ref="kt_login_forgot_submit"
                  class="btn btn-primary font-weight-bolder btn-block font-size-h6 px-15 py-4 my-3 mr-3"
                  style="
                    background-color: #18328d;
                    color: #fff;
                    border-color: #18328d;
                  "
                >
                  Kirim Email
                </button>
                <button
                  type="button"
                  id="kt_login_forgot_cancel"
                  class="btn btn-light-primary font-weight-bolder btn-block font-size-h6 px-15 py-4 my-3 mr-3"
                  @click="showForm('signin')"
                  style="
                    background-color: #e5eaee;
                    color: #979797;
                    border-color: #e5eaee;
                  "
                >
                  Kembali
                </button>
              </div>
            </form>
          </div>
          <!--end::Forgot-->
        </div>
      </div>
      <!--end::Content-->
    </div>

    <kebijakan-privasi ref="kebijakanPrivasi" />
    <syarat-ketentuan ref="syaratKetentuan" />

  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";
</style>

<script>
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";

// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";

import KTUtil from "@/assets/js/components/util";
// import { mapGetters } from "vuex";
import Swal from "sweetalert2";
import { handleErrors } from "@/core/appUtil/util";
import TokenService from "@/core/services/token.service";
import VueJwtDecode from "vue-jwt-decode";
import KebijakanPrivasi from "./KebijakanPrivasi";
import SyaratKetentuan from "./SyaratKetentuan";

// import { UPDATE_PERSONAL_INFO } from "@/core/services/store/profile.module";

const swalSuccess = Swal.mixin({
  customClass: {
    confirmButton: "btn btn-save",
    cancelButton: "btn btn-delete"
  },
  buttonsStyling: false
});

export default {
  name: "login-1",
  components: {
    KebijakanPrivasi,
    SyaratKetentuan
  },
  data() {
    return {
      state: "signin",
      form: {
        email: "",
        password: ""
      },
      forgot: {
        email: ""
      },
      register: {
        full_name: "",
        email: "",
        password: "",
        cpassword: ""
      }
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },

    backgroundImage() {
      return process.env.BASE_URL + "media/bg/merdeka.png";
    }
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/dashboard");
    }
  },
  mounted() {
    // Query Parameters for terms & conditions
    const allowedTerms = ["kebijakan privasi", "syarat ketentuan"];
    const term = this.$route.query.term;

    setTimeout(() => {
      if (term == allowedTerms[0]) {
        this.showKebijakan();
      } else if (term == allowedTerms[1]) {
        this.showSyarat()
      }
    }, 100);

    // Enable recaptcha badge
    setTimeout(() => {
      const recaptcha = this.$recaptchaInstance
      recaptcha.showBadge()
    }, 500)

    const signin_form = KTUtil.getById("kt_login_signin_form");
    // const signup_form = KTUtil.getById("kt_login_signup_form");
    const forgot_form = KTUtil.getById("kt_login_forgot_form");

    this.fv = formValidation(signin_form, {
      fields: {
        email: {
          validators: {
            notEmpty: {
              message: "Username harus diisi"
            },
            emailAddress: {
              message: "Email yang Anda masukkan tidak valid"
            }
          }
        },
        password: {
          validators: {
            notEmpty: {
              message: "Kata Sandi harus diisi"
            }
          }
        }
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap()
      }
    });

    this.fv2 = formValidation(forgot_form, {
      fields: {
        email: {
          validators: {
            notEmpty: {
              message: "Email harus diisi"
            },
            emailAddress: {
              message: "Email yang Anda masukkan tidak valid"
            }
          }
        }
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap()
      }
    });

    // Form Login
    let attempt = 0;

    this.fv.on("core.form.valid", async () => {
      attempt++;

      let email = this.form.email;
      let password = this.form.password;
      let reCaptcha = await this.recaptcha()

      // set spinner to submit button
      const submitButton = this.$refs["kt_login_signin_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      if (email && password && reCaptcha) {
        this.$store.dispatch("auth/login", { email, password, reCaptcha }).then(
          async data => {
            if (data.access_token) {
              localStorage.setItem("token", data.access_token);
              const parsed_token = VueJwtDecode.decode(data.access_token);
              const hasPermissions = parsed_token.role;
              const parsed_refresh_token = VueJwtDecode.decode(
                data.refresh_token
              );
              // this.$store.commit("", parsed_token);
              this.$store.commit("token/setAttribute", hasPermissions);
              this.$store.commit("token/setToken", data.access_token);
              this.$store.commit("token/setParsedToken", parsed_token);
              this.$store.commit("token/setRefreshToken", data.refresh_token);
              this.$store.commit(
                "token/setParsedRefreshToken",
                parsed_refresh_token
              );
              if (parsed_token.role.id === 2) {
                this.$store.commit("token/setIsSuperAdmin", true);
              } else {
                this.$store.commit("token/setIsSuperAdmin", false);
              }

              /*fetch(encodeURI(process.env.VUE_APP_URL_LOCAL + `/auth/user`), {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "bearer " + data.access_token
                }
              })
                .then(handleErrors)
                .then(response => response.json())
                .then(response => {
                  const userProfile = VueJwtDecode.decode(response);
                  this.$store.dispatch(UPDATE_PERSONAL_INFO, userProfile);
                });*/

              submitButton.classList.remove(
                "spinner",
                "spinner-light",
                "spinner-right"
              );
              await this.$router.push({ name: "dashboard" });
            } else {
              Swal.fire({
                title: "",
                text: "Email atau Kata sandi yang Anda masukkan salah",
                icon: "error",
                heightAuto: false
              });
              submitButton.classList.remove(
                "spinner",
                "spinner-light",
                "spinner-right"
              );
            }
          },
          error => {
            /*this.loading = false;
            this.message =
              (error.response && error.response.data) ||
              error.message ||
              error.toString();*/
            if (error.response && (error.response.status === 404 || error.response.status === 500)) {
              if (attempt <= 3) {
                submitButton.click();
              } else {
                Swal.fire({
                  title: "",
                  text: "User tidak ditemukan",
                  icon: "error",
                  heightAuto: false
                });
                submitButton.classList.remove(
                  "spinner",
                  "spinner-light",
                  "spinner-right"
                );
              }

            } else {
              Swal.fire({
                title: "",
                text: "Email atau Kata sandi yang Anda masukkan salah",
                icon: "error",
                heightAuto: false
              });
              submitButton.classList.remove(
                "spinner",
                "spinner-light",
                "spinner-right"
              );
            }
          }
        );
      }
    });

    this.fv.on("core.form.invalid", () => {
      Swal.fire({
        title: "",
        text: "Silakan lengkapi data Anda!",
        icon: "error",
        confirmButtonColor: "#18328D",
        heightAuto: false
      });
    });

    // Form Forgot Password

    this.fv2.on("core.form.valid", () => {
      let res = this.forgot.email;
      let email = encodeURIComponent(res);

      // set spinner to submit button
      const submitButton = this.$refs["kt_login_forgot_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      fetch(
        process.env.VUE_APP_URL_LOCAL +
          `/forgot-password/generate-code?email=${email}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "bearer " + TokenService.getLocalAccessToken()
          }
        }
      )
        .then(handleErrors)
        .then(response => response.json())
        .then(async () => {
          await swalSuccess.fire({
            title: "Berhasil",
            text:
              "Silakan cek email Anda untuk link konfirmasi lupa kata sandi",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#18328D",
            confirmButtonText: "OK"
          });
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );

          /*.catch(error => {
              console.log(error);
              Swal.fire({
                title: "Kesalahan",
                text: "Email tidak terdaftar",
                icon: "error",
                showCancelButton: false,
                confirmButtonText: "Kembali",
                confirmButtonColor: "#18328D"
              });
              submitButton.classList.remove(
                "spinner",
                "spinner-light",
                "spinner-right"
              );
            });*/
        })
        .catch(error => {
          console.log(error);
          Swal.fire({
            title: "Kesalahan",
            text: "Email tidak terdaftar",
            icon: "error",
            showCancelButton: false,
            confirmButtonText: "Kembali",
            confirmButtonColor: "#18328D"
          });
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
        });
    });

    this.fv2.on("core.form.invalid", () => {
      Swal.fire({
        title: "",
        text: "Silakan lengkapi data Anda!",
        icon: "error",
        confirmButtonColor: "#18328D",
        heightAuto: false
      });
    });
  },
  methods: {
    showKebijakan(){
      this.$refs.kebijakanPrivasi.show();
    },
    showSyarat(){
      this.$refs.syaratKetentuan.show();
    },
    showForm(form) {
      this.state = form;
      let form_name = "kt_login_" + form + "_form";
      KTUtil.animateClass(
        KTUtil.getById(form_name),
        "animate__animated animate__backInUp"
      );
    },

    redirKominfoSSO() {
      let clientid = process.env.VUE_APP_SSO_KOMINFO_CLIENT_ID;
      let baseURI = process.env.VUE_APP_SSO_KOMINFO_URL;
      let callbackUri = process.env.VUE_APP_SSO_KOMINFO_CALLBACK;
      location.replace(
        baseURI +
          "/auth/realms/SPBE/protocol/openid-connect/auth?client_id=" +
          clientid +
          "&state=&redirect_uri=" +
          callbackUri +
          "&response_type=code"
      );
    },

    redirPolpumSSO() {
      let baseURI = process.env.VUE_APP_SSO_POLPUM_URL;
      let callbackUri = process.env.VUE_APP_SSO_POLPUM_CALLBACK;
      location.replace(
        baseURI + "/rs/auth/web/check-login?redirect-url=" + callbackUri
      );
    },

    processSSO() {
      const signin_form = KTUtil.getById("kt_login_signin_form");

      // set spinner to submit button
      const submitButton = this.$refs["kt_login_signin_sso"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      const fv = formValidation(signin_form, {
        fields: {
          email: {
            validators: {
              notEmpty: {
                message: "Username harus diisi"
              }
            }
          },
          password: {
            validators: {
              notEmpty: {
                message: "Kata Sandi harus diisi"
              }
            }
          }
        },
        plugins: {
          trigger: new Trigger(),
          bootstrap: new Bootstrap()
        }
      });
      fv.resetForm();
      fv.validate().then(status => {
        if (status === "Valid") {
          let email = this.form.email;
          let password = this.form.password;

          // call SSO Kominfo
          if (email && password) {
            this.$store.dispatch("auth/loginSSO", { email, password }).then(
              async data => {
                if (data.access_token) {
                  localStorage.setItem("token", data.access_token);
                  const parsed_token = VueJwtDecode.decode(data.access_token);
                  const hasPermissions = parsed_token.role;
                  const parsed_refresh_token = VueJwtDecode.decode(
                    data.refresh_token
                  );
                  // this.$store.commit("", parsed_token);
                  this.$store.commit("token/setAttribute", hasPermissions);
                  this.$store.commit("token/setToken", data.access_token);
                  this.$store.commit("token/setParsedToken", parsed_token);
                  this.$store.commit(
                    "token/setRefreshToken",
                    data.refresh_token
                  );
                  this.$store.commit(
                    "token/setParsedRefreshToken",
                    parsed_refresh_token
                  );
                  if (parsed_token.role.id === 2) {
                    this.$store.commit("token/setIsSuperAdmin", true);
                  } else {
                    this.$store.commit("token/setIsSuperAdmin", false);
                  }
                  submitButton.classList.remove(
                    "spinner",
                    "spinner-light",
                    "spinner-right"
                  );
                  await this.$router.push({ name: "dashboard" });
                } else {
                  Swal.fire({
                    title: "",
                    text:
                      "Email/Username/NIP/NIK atau Kata sandi yang Anda masukkan salah atau tidak dikenali SSO",
                    icon: "error",
                    heightAuto: false
                  });
                  submitButton.classList.remove(
                    "spinner",
                    "spinner-light",
                    "spinner-right"
                  );
                }
              },
              error => {
                console.log(error);
                Swal.fire({
                  title: "",
                  text:
                    "Email/Username/NIP/NIK atau Kata sandi yang Anda masukkan salah atau tidak dikenali SSO",
                  icon: "error",
                  heightAuto: false
                });
                submitButton.classList.remove(
                  "spinner",
                  "spinner-light",
                  "spinner-right"
                );
              }
            );
          }
        } else {
          console.log("Invalid Form");
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
        }
      });
    },

    async recaptcha() {
      // (optional) Wait until recaptcha has been loaded.
      await this.$recaptchaLoaded()

      // Execute reCAPTCHA with action "login".
      const token = await this.$recaptcha('login')

      return token
    },

    showPassword(e) {
      let tagName = e.target.nodeName.toLowerCase()
      let selector = e.target

      if (tagName === 'i') {
        selector = selector.parentElement
      }

      // Change icon class name
      let icon = selector.querySelector('i')

      if (icon.classList.contains('fa-eye')) {
        icon.className = icon.className.replace('fa-eye', 'fa-eye-slash')
        selector.parentElement.previousElementSibling.type = 'text'
      } else {
        icon.className = icon.className.replace('fa-eye-slash', 'fa-eye')
        selector.parentElement.previousElementSibling.type = 'password'
      }
    }
  },
  beforeDestroy() {
    // Hide badge recaptcha
    const recaptcha = this.$recaptchaInstance
    recaptcha.hideBadge()
  }
};
</script>
