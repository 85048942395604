<template>
  <b-modal
    id="syarat-ketentuan"
    hide-footer
    title="Syarat & Ketentuan"
    size="xl"
  >
    <p class="d-block">
      Kebijakan Privasi ini bertujuan untuk membantu Anda memahami informasi
      yang kami kumpulkan, alasan kami mengumpulkannya, <br />
      dan cara untuk memperbarui, mengelola, mengekspor, dan menghapus informasi
      Anda
    </p>

    <ol style="margin-top: 3rem" class="ordered">
      <li class="bold">Pernyataan</li>
      <p>
        Dengan melanjutkan akses atau penggunaan terhadap
        <a :href="baseUrl" target="_blank" rel="noopener noreferrer">{{
          baseUrl
        }}</a
        >, pengguna setuju untuk tunduk dan mematuhi semua ketentuan peraturan
        perundang-undangan terkait dan ketentuan penggunaan ini, termasuk semua
        perubahannya dan ketentuan penggunaan dari setiap layanan dan konten.
        Segera hentikan akses atau penggunaan
        <a :href="baseUrl" target="_blank" rel="noopener noreferrer">{{
          baseUrl
        }}</a>
        jika pengguna tidak setuju dengan bagian apapun dari ketentuan
        penggunaan ini.
      </p>

      <li class="bold">Akun dan Keanggotaan</li>
      <p>
        Jika Anda membuat akun di situs web kami, Anda bertanggung jawab untuk
        menjaga keamanan akun Anda dan Anda bertanggung jawab penuh atas semua
        aktivitas yang terjadi di bawah akun dan tindakan apa pun yang diambil
        sehubungan dengan itu. Kami dapat, tetapi tidak memiliki kewajiban
        untuk, memantau dan meninjau akun baru sebelum Anda dapat masuk dan
        menggunakan Layanan Kami. Anda harus segera memberitahu Kami tentang
        segala penggunaan akun Anda yang tidak sah atau pelanggaran keamanan
        lainnya. Kami tidak akan bertanggung jawab atas tindakan atau kelalaian
        yang disebabkan oleh Anda, termasuk kerusakan apa pun yang timbul
        sebagai akibat dari tindakan atau kelalaian tersebut. Kami dapat
        menangguhkan, menonaktifkan, atau menghapus akun Anda jika Kami
        memutuskan bahwa Anda telah melanggar ketentuan dalam Perjanjian ini.
        Jika Kami menghapus akun Anda karena alasan di atas, Anda tidak boleh
        mendaftar ulang untuk Layanan Kami. Kami dapat memblokir alamat email
        Anda dan alamat protokol internet untuk mencegah pendaftaran lebih
        lanjut.
      </p>

      <li class="bold">Penggunaan yang Dilarang</li>
      <p>
        Selain persyaratan lain sebagaimana diatur dalam Perjanjian ini, Anda
        dilarang menggunakan situs web atau kontennya:
      </p>

      <ol class="alpha-ordered">
        <li>untuk tujuan apa pun yang melanggar hukum;</li>
        <li>
          untuk meminta orang lain untuk melakukan atau berpartisipasi dalam
          tindakan yang melanggar hukum;
        </li>
        <li>melanggar peraturan atau hukum nasional;</li>
        <li>
          untuk melanggar hak kekayaan intelektual Kami atau hak kekayaan
          intelektual orang lain;
        </li>
        <li>untuk mengirimkan informasi yang salah atau menyesatkan;</li>
        <li>
          untuk mengunggah atau mengirimkan virus atau segala jenis malicious
          code lainnya yang akan atau dapat digunakan dengan cara apa pun yang
          akan memengaruhi fungsionalitas atau operasi Layanan atau situs web
          terkait, situs web lain, atau internet;
        </li>
        <li>untuk mengumpulkan atau melacak informasi pribadi orang lain;</li>
        <li>
          melakukan tindakan berikut namun tidak terbatas pada spamming,
          phishing, pharming, pretexting, spidering, web crawling, dan/atau
          scraping;
        </li>
        <li>untuk tujuan pencabulan atau tidak bermoral; atau</li>
        <li>
          untuk mengganggu atau menghindari fitur keamanan Layanan atau situs
          web terkait, situs web lain, atau internet. Kami berhak untuk
          menghentikan penggunaan Layanan oleh Anda atau situs web terkait
          karena melanggar salah satu dari penggunaan yang dilarang;
        </li>
        <li>
          untuk mengambil tangkapan layar (screenshot) pada halaman atau data
          yang bersifat rahasia, belum final dan/atau belum resmi dipublikasi
          menjadi informasi publik; bentuk informasi yang dapat dibagikan harus
          merupakan link resmi dari website ini dan bukan merupakan hasil
          tangkapan layar.
        </li>
      </ol>

      <li class="bold">Hak kekayaan intelektual</li>
      <p>
        Perjanjian ini tidak menyerahkan kepada Anda segala bentuk kekayaan
        intelektual yang dimiliki oleh Operator situs web atau pihak ketiga.
        Semua hak, judul, kepentingan dan properti tersebut akan tetap (di
        antara para pihak) semata-mata dimiliki oleh Operator situs web. Semua
        merek dagang, merek Layanan, grafik, dan logo yang digunakan sehubungan
        dengan situs web atau Layanan Kami, adalah merek dagang atau merek
        dagang terdaftar dari Operator situs web atau pemberi lisensi Operator
        situs web. Merek dagang, tanda Layanan, gambar dan logo lain yang
        digunakan sehubungan dengan situs web atau Layanan Kami mungkin
        merupakan bagian dari pihak ketiga lainnya. Penggunaan Anda atas situs
        web dan Layanan Kami tidak memberikan Anda hak atau lisensi untuk
        memproduksi atau menggunakan situs web atau merek dagang pihak ketiga
        mana pun.
      </p>

      <li class="bold">Keamanan</li>
      <p>
        Kami senantiasa melakukan upaya yang wajar untuk menjaga situs Web ini
        berfungsi dan berjalan lancar. Bagaimana pun juga, kami tidak
        bertanggung jawab dan tidak akan bertanggung jawab atas
        ketidaktersediaan situs Web dan/atau fitur Layanan yang disebabkan oleh
        berbagai alasan, termasuk namun tidak terbatas pada keperluan
        pemeliharaan atau masalah teknis. Namun demikian, Anda mengetahui dan
        setuju bahwa transmisi atau akses internet tidak selamanya aman dan
        pribadi, dan karenanya setiap pesan atau informasi yang anda kirimkan
        atau gunakan dalam situs Web ini mungkin dapat dibajak atau diambil oleh
        pihak ketiga yang tidak bertanggung jawab.
      </p>

      <li class="bold">Penerimaan persyaratan ini</li>
      <p>
        Anda memahami bahwa Anda telah membaca Perjanjian ini dan menyetujui
        semua syarat dan ketentuannya. Dengan menggunakan situs web atau
        Layanannya, Anda setuju untuk terikat Perjanjian ini. Jika Anda tidak
        setuju untuk mematuhi ketentuan Perjanjian ini, Anda tidak berwenang
        untuk menggunakan atau mengakses situs web dan Layanannya.
      </p>

      <li class="bold">Perubahan pada Persyaratan ini</li>
      <p>
        Kami dapat memperbarui Perjanjian ini dari waktu ke waktu. Dengan
        demikian, Anda disarankan untuk meninjau halaman ini secara berkala
        untuk setiap perubahan. Kami akan memberitahukan Anda tentang perubahan
        apapun dengan mengumumkan Perjanjian baru di situs web ini.
      </p>

      <li class="bold">Tautan ke Situs Lain</li>
      <p>
        Layanan ini mungkin berisi tautan ke situs lain. Jika Anda mengklik
        tautan pihak ketiga, Anda akan diarahkan ke situs itu. Perhatikan bahwa
        situs eksternal ini tidak dioperasikan oleh Kami. Oleh karena itu, Kami
        sangat menyarankan Anda untuk meninjau Kebijakan Privasi dari situs web
        ini. Kami tidak memiliki kendali dan tanggung jawab atas konten,
        kebijakan privasi, atau praktik dari situs atau Layanan pihak ketiga.
      </p>
    </ol>
  </b-modal>
</template>

<style>
.bold {
  font-weight: bold;
}
ol.alpha-ordered {
  margin-top: 1rem;
  list-style: lower-alpha;
  list-style-position: inside;
}
</style>

<script>
export default { 
  data(){
    return {
      baseUrl: window.location.origin
    }
  },
  methods: {
    show() {
      this.$bvModal.show('syarat-ketentuan');
    },
    hide() {
      this.$bvModal.hide('syarat-ketentuan');
    },
  },
};
</script>
