<template>
  <b-modal
    id="kebijakan-privasi"
    hide-footer
    title="Kebijakan Privasi"
    size="xl"
  >
    <p class="d-block">
      Kebijakan Privasi ini bertujuan untuk membantu Anda memahami informasi
      yang kami kumpulkan, alasan kami mengumpulkannya, <br />
      dan cara untuk memperbarui, mengelola, mengekspor, dan menghapus informasi
      Anda
    </p>

    <ol style="margin-top: 3rem" class="ordered">
      <li class="bold">Pengakuan Dan Persetujuan Kebijakan Privasi ini</li>
      <p>
        Anda wajib membaca Kebijakan Privasi ini sebelum mendaftarkan diri dan
        menggunakan Layanan. Dengan mendaftarkan diri dan menggunakan setiap
        produk dan/atau Layanan kami, Anda menyatakan bahwa Anda telah membaca,
        memahami dan setuju terhadap ketentuan-ketentuan Kebijakan Privasi ini.
        Pengakuan dan persetujuan Anda terhadap Kebijakan Privasi ini juga
        berkaitan dengan ketentuan yang tercantum berdasarkan Ketentuan
        Penggunaan yang dianggap sebagai satu kesatuan dan tidak dapat
        dipisahkan.
      </p>

      <li class="bold">Rahasia Pribadi</li>
      <p>
        Halaman ini digunakan untuk menginformasikan Anda mengenai kebijakan
        Kami dengan pengumpulan, penggunaan, dan pengungkapan Informasi Pribadi
        jika ada yang memutuskan untuk menggunakan Layanan Kami. Jika Anda
        memilih untuk menggunakan Layanan Kami, maka Anda menyetujui pengumpulan
        dan penggunaan informasi yang terkait dengan kebijakan ini. Informasi
        Pribadi yang Kami kumpulkan digunakan untuk menyediakan dan meningkatkan
        Layanan. Kami tidak akan menggunakan atau membagikan informasi Anda
        dengan siapa pun kecuali seperti yang dijelaskan dalam Kebijakan Privasi
        ini. Istilah-istilah yang digunakan dalam Kebijakan Privasi ini memiliki
        arti yang sama seperti dalam Syarat dan Ketentuan Kami, yang dapat
        diakses di situs web kecuali ditentukan lain dalam Kebijakan Privasi
        ini.
      </p>

      <li class="bold">Pengumpulan Informasi Secara Otomatis</li>
      <p>
        Ketika Anda mengunjungi situs web, server Kami secara otomatis merekam
        informasi yang dikirimkan oleh browser Anda. Data ini dapat mencakup
        informasi seperti alamat IP perangkat Anda, jenis dan versi peramban,
        jenis dan versi sistem operasi, preferensi bahasa atau halaman web yang
        Anda kunjungi sebelum Anda datang ke situs web Kami, halaman situs web
        Kami yang Anda kunjungi, waktu yang dihabiskan untuk halaman-halaman
        itu, informasi yang Anda cari di situs web Kami, waktu dan tanggal
        akses, dan statistik lainnya.
      </p>

      <li class="bold">Pengumpulan Informasi Pribadi</li>
      <p>
        Anda dapat mengunjungi situs web tanpa memberi tahu Kami siapa Anda atau
        mengungkapkan informasi yang dengannya seseorang dapat mengidentifikasi
        Anda sebagai individu yang spesifik dan dapat diidentifikasi. Namun,
        jika Anda ingin menggunakan beberapa fitur situs web, Anda akan diminta
        untuk memberikan Informasi Pribadi tertentu (misalnya, nama dan alamat
        email Anda). Kami menerima dan menyimpan informasi apa pun yang Anda
        berikan secara sadar kepada Kami, saat Anda melakukan lupa kata sandi di
        situs web. Bila diperlukan, informasi ini dapat mencakup alamat email
        Anda. Anda dapat memilih untuk tidak memberikan Informasi Pribadi Anda
        kepada Kami, tetapi Anda mungkin tidak dapat memanfaatkan beberapa fitur
        situs web.
      </p>

      <li class="bold">Mengelola Informasi Pribadi</li>
      <p>
        Anda dapat mengakses, menambah dan memperbarui Informasi Pribadi
        tertentu tentang Anda. Informasi yang Anda lihat dan perbarui dapat
        berubah seiring perubahan situs web. Namun, ketika Anda memperbarui
        informasi, Kami dapat menyimpan salinan informasi yang tidak direvisi
        dalam catatan Kami. Beberapa informasi mungkin tetap berada dalam sistem
        Kami. Kami akan menyimpan dan menggunakan Informasi Pribadi Anda selama
        periode yang diperlukan untuk mematuhi kewajiban hukum Kami,
        menyelesaikan perselisihan, dan melaksanakan perjanjian kecuali apabila
        diperlukan periode retensi yang lebih lama atau diizinkan oleh hukum.
        Kami dapat menggunakan semua data gabungan yang berasal dari Informasi
        Pribadi yang Anda masukkan setelah Anda perbarui tetapi tidak akan
        mengidentifikasi Anda secara pribadi.
      </p>

      <li class="bold">Penggunaan Informasi Yang Dikumpulkan</li>
      <p>
        Setiap informasi yang Kami kumpulkan dari Anda dapat digunakan untuk
        mempersonalisasi pengalaman Anda; memperbaiki situs web Kami;
        meningkatkan Layanan pengguna, menanggapi pertanyaan dan email pengguna
        Kami; mengirimkan email pemberitahuan seperti aktivasi akun, pengingat
        kata sandi, pembaharuan, dan lain-lain; serta menjalankan dan
        mengoperasikan situs web Kami. Informasi yang dikumpulkan secara
        otomatis hanya digunakan untuk mengidentifikasi kemungkinan kasus
        penyalahgunaan dan menetapkan informasi statistik mengenai penggunaan
        situs web. Informasi statistik ini tidak dikumpulkan sedemikian rupa
        sehingga tidak akan mengidentifikasi pengguna sistem.
      </p>

      <li class="bold">Cookies</li>
      <p>
        Situs web ini menggunakan "cookies" untuk membantu mempersonalisasi
        pengalaman online Anda. Cookies adalah file teks yang ditempatkan di
        hard disk Anda oleh server halaman web. Cookies tidak dapat digunakan
        untuk menjalankan program atau mengirimkan virus ke komputer Anda.
        Cookies ditetapkan secara unik untuk Anda dan hanya dapat dibaca oleh
        server web dalam domain yang mengeluarkan cookies untuk Anda. Kami dapat
        menggunakan cookies untuk mengumpulkan, menyimpan, dan melacak informasi
        untuk keperluan statistik untuk mengoperasikan situs web dan Layanan
        Kami. Anda memiliki pilihan untuk menerima atau menolak cookies.
        Sebagian besar browser web secara otomatis akan menerima cookies, tetapi
        biasanya Anda dapat mengubah pengaturan browser Anda untuk menolak
        cookies jika diinginkan.
      </p>

      <li class="bold">Tautan ke Situs Lain</li>
      <p>
        Layanan ini mungkin berisi tautan ke situs lain. Jika Anda mengklik
        tautan pihak ketiga, Anda akan diarahkan ke situs itu. Perhatikan bahwa
        situs eksternal ini tidak dioperasikan oleh Kami. Oleh karena itu, Kami
        sangat menyarankan Anda untuk meninjau Kebijakan Privasi dari situs web
        ini. Kami tidak memiliki kendali dan tanggung jawab atas konten,
        kebijakan privasi, atau praktik dari situs atau Layanan pihak ketiga.
      </p>

      <li class="bold">Jangan Lacak Sinyal</li>
      <p>
        Beberapa browser menggabungkan fitur Jangan Lacak yang memberi sinyal ke
        situs web yang Anda kunjungi dan Anda tidak ingin aktivitas online Anda
        dilacak. Pelacakan tidak sama dengan penggunaan atau pengumpulan
        informasi sehubungan dengan situs web. Untuk tujuan ini, pelacakan
        mengacu pada pengumpulan informasi yang dapat diidentifikasi secara
        pribadi dari pengguna yang menggunakan atau mengunjungi situs web atau
        layanan online saat pengguna bergerak melintasi situs web yang berbeda
        dari waktu ke waktu. Situs web Kami tidak melacak penggunanya dari waktu
        ke waktu dan di situs web pihak ketiga. Namun, beberapa situs pihak
        ketiga dapat melacak aktivitas penjelajahan Anda saat mereka menyajikan
        konten kepada Anda, yang memungkinkan mereka menyesuaikan apa yang
        mereka presentasikan kepada Anda.
      </p>

      <li class="bold">Informasi Keamanan</li>
      <p>
        Kami mengamankan informasi yang Anda berikan di server komputer dalam
        lingkungan yang terkendali dan aman, terlindung dari akses, penggunaan,
        atau pengungkapan yang tidak sah. Kami menjaga pengamanan administrasi,
        teknis, dan fisik yang wajar dalam upaya perlindungan terhadap akses,
        penggunaan, modifikasi, dan pengungkapan Informasi Pribadi yang tidak
        sah dalam kendali dan pengawasannya. Namun, tidak ada transmisi data
        melalui Internet atau jaringan nirkabel yang dapat dijamin. Karena itu,
        sementara Kami berusaha melindungi Informasi Pribadi Anda, Anda mengakui
        bahwa:
      </p>

      <ol class="roman-ordered">
        <li>
          ada batasan keamanan dan privasi dari internet yang berada di luar
          kendali Kami;
        </li>
        <li>
          keamanan, integritas, dan privasi dari setiap dan semua informasi dan 
          data yang dipertukarkan antara Anda dan situs web Kami tidak dapat 
          dijamin; dan
        </li>
        <li>
          informasi dan data tersebut dapat dilihat atau dirusak dalam 
          perjalanan oleh pihak ketiga, meskipun ada upaya terbaik dalam menjaga 
          informasi dan data tersebut.
        </li>
      </ol>

      <li class="bold">Pelanggaran Data</li>
      <p>
        Jika Kami mengetahui bahwa keamanan situs web telah disusupi atau
        Informasi Pribadi pengguna telah diungkapkan kepada pihak ketiga yang
        tidak terkait sebagai akibat dari aktivitas eksternal, termasuk, tetapi
        tidak terbatas pada, serangan keamanan atau penipuan, Kami berhak untuk
        mengambil langkah-langkah yang pantas secara wajar, termasuk, tetapi
        tidak terbatas pada, penyelidikan dan pelaporan, serta pemberitahuan dan
        kerja sama dengan otoritas penegak hukum. Jika terjadi pelanggaran data,
        Kami akan melakukan upaya yang wajar untuk memberi tahu orang-orang yang
        terkena dampak jika Kami yakin bahwa ada risiko yang wajar akan
        merugikan pengguna sebagai akibat dari pelanggaran tersebut atau jika
        pemberitahuan sebaliknya diharuskan oleh hukum. Ketika Kami melakukan
        hal-hal di atas, Kami akan mengumumkan pemberitahuan di situs web,
        sosial media kami dan/atau mengirimkan Anda email.
      </p>

      <li class="bold">Kepatuhan Terhadap Undang-undang</li>
      <p>
        Kami secara teratur meninjau Kebijakan Privasi ini dan memastikan bahwa
        kami memproses informasi Anda sesuai dengan kebijakan tersebut.
      </p>

      <li class="bold">Perubahan Pada Kebijakan Privasi Ini</li>
      <p>
        Kami dapat memperbarui Kebijakan Privasi ini dari waktu ke waktu. Dengan
        demikian, Anda disarankan untuk meninjau halaman ini secara berkala
        untuk setiap perubahan. Dengan tetap mengakses dan menggunakan layanan
        kami, maka Anda dianggap menyetujui perubahan-perubahan dalam Kebijakan
        Privasi ini.
      </p>

    </ol>
  </b-modal>
</template>

<style>
.bold {
  font-weight: bold;
}
ol.roman-ordered {
  margin-top: 1rem;
  list-style: lower-roman;
  list-style-position: inside;
}
</style>

<script>
export default { 
  methods: {
    show() {
      this.$bvModal.show('kebijakan-privasi');
    },
    hide() {
      this.$bvModal.hide('kebijakan-privasi');
    },
  },
};
</script>
